/* hide reCaptcha */
.grecaptcha-badge {
  display: none;
}


/* Luis styles -- token manager */

/* overview styles */
.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.overview-title {
  font-family: 'IBM Plex Sans', sans-serif; 
  font-weight: 700;
  font-style: normal;
  font-size: 1.875rem;
}

.box-content {
  max-width: 320px;
  width: 100%;
  height: 140px; 
  margin-left: 5px; 
  margin-right: 5px; 
  border: 1px solid #ddd;
  border-radius: 5px;
  background: var(--Other---White, #FFF);
  display: flex;
  align-items: center;
  justify-content: center; 
}


.box-content h2 {
  color: var(--Accent-color-1, #142830);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.box-overview-number{
  color: var(--Accent-color-1, #142830);
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
}
.flex-parent {
  display: flex;
  flex-direction: column;
  width: 100%; 
  align-items: stretch; 
}

.text-overview-container {
  background: #E9EBEF;
  border-radius: 5px;
  padding: 20px;
  width: 100%; 
  min-height: 200px; 
  height: auto; 
}




/* activity Log */

.main-container {
  display: flex;
}

.channels-title {
  font-family: 'IBM Plex Sans', sans-serif; 
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
}
.channels-sidebar {
  display: flex;
  min-height: 97vh;
}

.channels-sidebar > div:first-child {
  width: 250px; 
  background-color: #CFD3D8; 
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}

.channels-sidebar h2 {
  margin-bottom: 20px;
}

.channels-sidebar input[type="text"] {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #CFD3D8;
  border-radius: 10px; 
  background: #E8EAF0;
}

.channels-sidebar ul {
  list-style: none;
  padding: 0;
}

.channels-sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
}

.content {
  flex-grow: 1; 
  background-color: #fff;
  padding: 20px;
}

.header-tokens {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}





.token-title {
  font-family: 'US-Sans-Servf', sans-serif;
  font-weight: 700; /* Bold */
  font-style: normal;
  font-size: 2.25rem;
}

.create-user-description{
  color: #7C848B;
  font-weight: 500;
}
.unstyled-content, 
.unstyled-content * {
    all: revert;
}

.text-shadow-inset {
  background: linear-gradient(to right, rgba(232, 58, 75, 0.3), rgba(232, 58, 75, 0.1));
}

.input-style {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 0.1px solid #ccc; 
  border-radius: 0.5rem; 
  outline: none; 
  transition: border-color 0.3s; 
  margin-bottom: 16px;
}

.input-style:focus {
  border-color: #007BFF; 
}

.save-and-continue {
  background-color: rgb(230, 44, 62); 
  color: white; 
  padding: 0.5rem 1rem;
  border: none; 
  border-radius: 0.5rem; 
  cursor: pointer; 
  transition: background-color 0.3s;
}
.textarea-style {
  height: 110px;  
  padding: 8px;   
  resize: none;  
}


.save-and-continue:hover {
  background-color: rgb(200, 24, 42); 
}

.circle-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(186, 181, 181);
  color: rgb(140, 137, 137);
  font-weight: bold;
  z-index: 1;
}

.circle.active {
  background-color: rgb(245, 198, 203);
  color: rgb(230, 44, 62);
}
.ring2 {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 46px;
  height: 46px;
  border: 1px solid rgb(230, 44, 62);
  border-radius: 50%;
  z-index: 0;
}
.custom-width {
  width: 62.5%;
}

.input-style::placeholder {
  font-size: 0.875rem; 
  font-weight: 550; 
  opacity: 0.5; 
}


/* luis -- Tokens enrolled */
.filter-button-token{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;   
  background-color: #fff; 
  color:#142830;    
  font-weight: 600;
  font-size: 14px;
  cursor: pointer; 

  border: 1px solid #ccc;
  border-radius: 5px;
}

.token-card h2 {
  font-size: 18px;
  color: #142830;
  font-weight: 600;
}

.token-card p {
  font-size: 13px;
  font-weight: 550;
}

.header-tokens {
  display: flex;
  align-items: center;  
}

.view-switch {
  margin-left: auto; 
}

.container-tokens {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.header-tokens {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.activity-log-title{
  font-family: 'UI-Sans-Serif', sans-serif; 
  font-style: normal;
  font-size: 35px;
  font-weight: 700;
}


.input-tokens {
  width: 350px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.tokens-table-container{
  background-color: #fff;
  border-radius: 5px;
}
.tokens-table {
  width: 100%;
  border-collapse: collapse; 
}
.tokens-table th, .tokens-table td {
  padding: 10px 12px; 
  border-bottom: 1.5px solid #E2E6EA;  
  text-align: left;
}

.tokens-table tbody tr:last-child td {
  border-bottom: none; 
}


.tokens-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px ; 
  justify-content: space-between; 
}

.token-card {
  position: relative;
  flex: 0 0 calc(25% - 10px); 
  height: 180px;
  overflow: hidden;
  background-color: white;
  border-radius: 10px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: flex-start; 
  padding-left: 10px;
}
.state-label-token {
  position: absolute;
  top: 10px;

  border-radius: 16px;
  background-color: #02993E;
  color: #fff;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 600;
}

.token-url{
  margin-bottom: 20px;
}
.separator-line {
  width: 100%;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  position: absolute; 
  bottom: 60px;
  left: 0;
}
.token-status {
  position: absolute;
  bottom: 10px; 
  right: 10px; 

  padding: 3px 10px;
  border-radius: 16px;
  background-color: rgba(2, 153, 62, 0.20);
  color: #02993E;
  font-size: 14px;
  font-weight: 600; 
}

.token-type {
  position: absolute;
  bottom: 10px; 
  left: 10px;

  padding: 3px 10px;
  border-radius: 16px;
  background-color: rgba(130, 32, 207, 0.20);
  color: #8220CF;
  font-size: 14px;
  font-weight: 600; 
}

.tokens-table th:nth-child(1), .tokens-table td:nth-child(1) { width: 30px; } /* # */
.tokens-table th:nth-child(2), .tokens-table td:nth-child(2) { width: 200px; } /* username */
.tokens-table th:nth-child(3), .tokens-table td:nth-child(3) { width: 200px; } /* firstname */
.tokens-table th:nth-child(4), .tokens-table td:nth-child(4) { width: 200px; } /* lastname*/
.tokens-table th:nth-child(5), .tokens-table td:nth-child(5) { width: 400px; }  /* email */



.view-option .icon-placeholder {
  width: 20px;
  height: 20px;
  background-color: gray; 
  margin-right: 10px; 
}

.view-option.selected {
  background-color: rgba(236, 40, 66, 0.15);
}

.add-new-token {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;   
  border-radius: 10px; 
  background-color: #EC2842; 
  color: #fff;    
  font-weight: 600;
  cursor: pointer;   
}

.token-status { 
  padding-bottom: 1px; 
}


/* settings */

.settings-title {
  font-family: 'UI-Sans-Serif', sans-serif; 
  font-style: normal;
  font-size: 35px;
  font-weight: 700;
}


.nav-link {
  transition: color 0.3s ease, border-color 0.3s ease;
}

/* settings 
    roles management */

.roles-management-container {
  display: flex;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.sidebar {
  width: 250px; 
  background-color: #F2F2F2; 
  padding: 20px;
  box-sizing: border-box;
  height: 100vh; 
}
.create-role-button {
  width: 100%;
  background-color: #4CAF50; 
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.create-role-button:hover {
  background-color: #45a049;
}

.system-feature {
  padding: 10px 0;
  cursor: pointer;
}


.role-creation-panel {
  flex-grow: 1;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 60px; 
  overflow-y: auto; 
  max-height: calc(100vh - 40px); 
  border-radius: 10px;
  margin-top: 10px; 
  box-shadow: 0px 4px 21px 0px rgba(20, 40, 48, 0.04);
}

.system-features .system-feature {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #142830;
}

.system-features .system-feature.active {
  background-color: #DFE3E7;
}

/* settings 
    roles management 
      create Role*/

      .create-new-role-title {
        font-family: 'IBM Plex Sans', sans-serif; 
        font-weight: 700;
        font-style: normal;
        font-size: 1.5rem;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .create-role-btn {
        background-color: #EC2842; 
        color: white;
        padding: 10px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
      }
      
      .divider {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      
      .role-form .form-row {
        display: flex;
        justify-content: space-between;
      }
      
      .role-form .form-field {
        margin-bottom: 10px;
        flex: 1; 
      }
      
      .role-form label {
        display: block;
        margin-bottom: 5px;
      }

      .role-form .form-row .form-field:first-child {
        margin-right: 20px; 
      }

      .role-form input[type="text"],
      .role-form input[type="color"],
      .role-form textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .role-form textarea {
        height: 100px;
      }
           /* color input */
           .color-picker {
            display: flex;
            align-items: center;
        }
        
        .color-picker input[type="text"] {
            flex-grow: 1;
            margin-right: 10px;
            padding-right: 45px; 
            box-sizing: border-box; 
        }
        
        .color-input-wrapper {
            position: relative;
            margin-left: -50px; /* Adjust position to the left */
            width: 40px;
        }
        
        .color-picker input[type="color"] {
            width: 35px;
            height: 35px;
            border: none;
            padding: 0;
            position: absolute; 
            right: 5px; 
            top: 50%;
            transform: translateY(-50%); 
            border-radius: 50%;
            cursor: pointer;
            -webkit-appearance: none;
        }
        
        .color-picker input[type="color"]::-webkit-color-swatch-wrapper {
            padding: 0;
        }
        
        .color-picker input[type="color"]::-webkit-color-swatch {
            border: none;
            border-radius: 50%;
        }
        
/* table */
.permissions-table {
  width: 100%;
  border-collapse: collapse;
}

.permissions-table td {
  padding: 8px;
  border-bottom: 1px solid #ccc; 
}

input[type="checkbox"] {
  margin-right: 5px;
  color: #EC2842; 
  border-radius: 50%; 
}

input[type="checkbox"]:checked + label {
  color: #FF0000 !important;
}

.column-header-cell {
  background-color: #f0f0f0; 
}




:root {
	--hue: 223;
	--bg: hsl(var(--hue),10%,90%);
	--fg: hsl(var(--hue),10%,10%);
	--transDur: 0.15s;
	/* font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320)); */
}


.notification {
	padding-bottom: 0.75em;
	position: fixed;
	top: 14px;
	right: 1.5em;
	width: 18.75em;
	max-width: calc(100% - 3em);
	transition: transform 0.15s ease-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.notification__box,
.notification__content,
.notification__btns {
	display: flex;
}
.notification__box,
.notification__content {
	align-items: center;
}
.notification__box {
	animation: flyIn 0.3s ease-out;
	background-color: hsl(0,0%,100%);
	border-radius: 0.75em;
	box-shadow: 0 0.5em 1em hsla(var(--hue),10%,10%,0.1);
	height: 3em;
	transition:
	background-color var(--transDur),
	color var(--transDur);
}
.notification--out .notification__box {
	animation: flyOut 0.3s ease-out forwards;
}
.notification__content {
	padding: 0.375em 1em;
	width: 100%;
	height: 100%;
}
.notification__icon {
	flex-shrink: 0;
	margin-right: 0.75em;
	width: 2em;
	height: 2em;
}
.notification__icon-svg {
	width: 100%;
	height: auto;
}
.notification__text {
	line-height: 1.333;
}
.notification__text-title {
	font-size: 0.75em;
	font-weight: bold;
}
.notification__text-context {
	font-size: 10px !important;
	opacity: 0.75;
}
.notification__btns {
	box-shadow: -1px 0 0 hsla(var(--hue),10%,10%,0.15);
	flex-direction: column;
	flex-shrink: 0;
	min-width: 4em;
	height: 100%;
	transition: box-shadow var(--transDur);
}
.notification__btn {
	background-color: transparent;
	box-shadow: 0 0 0 hsla(var(--hue),10%,10%,0.5) inset;
	font-size: 0.6em;
	line-height: 1;
	font-weight: 500;
	height: 100%;
	padding: 0 0.5rem;
	transition:
		background-color var(--transDur),
		color var(--transDur);
	-webkit-appearance: none;
	appearance: none;
	-webkit-tap-highlight-color: transparent;
}
.notification__btn-text {
	display: inline-block;
	pointer-events: none;
}
.notification__btn:first-of-type {
	border-radius: 0 0.75rem 0 0;
}
.notification__btn:last-of-type {
	border-radius: 0 0 0.75rem 0;
}
.notification__btn:only-child {
	border-radius: 0 0.75rem 0.75rem 0;
}
.notification__btn + .notification__btn {
	box-shadow: 0 -1px 0 hsla(var(--hue),10%,10%,0.15);
	font-weight: 400;
}
.notification__btn:active,
.notification__btn:focus {
	background-color: hsl(var(--hue),10%,95%);
}
.notification__btn:focus {
	outline: transparent;
}


/* Animations */
@keyframes flyIn {
	from {
		transform: translateX(calc(100% + 1.5em));
	}
	to {
		transform: translateX(0);
	}
}
@keyframes flyOut {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(100% + 1.5em));
	}
}